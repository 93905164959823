import config from "./Config.ts";

function environment() {
    return config.isDev() ? 'dev' : 'prod';
}

export function resourceGroup(appName: string) {
    const env = environment();
    return `${env}-marked-${appName}`;
}

// "/subscriptions/6f8b93b9-0fc0-496c-baf2-39c9588a5432/resourcegroups/prod-marked-uc-eventconsumer/providers/microsoft.web/sites/prod-marked-uc-eventconsumer-app"
// -> uc-eventconsumer
export function extractApp(resourceId: string) {
    const split = resourceId.split("/");
    const applicationId = split[split.length - 1];
    return applicationId.replace(`${environment()}-marked-`, '')
        .replace('-app', '');

}

export class App {
    readonly name: string;
    readonly hostname: string;
    readonly logsUrl: string;


    constructor(name: string,
                hostname: string,
                logsUrl: string) {
        this.name = name;
        this.hostname = hostname;
        this.logsUrl = logsUrl
    }
}

const markedApplicationsNames = [
    'bp-api',
    'bisnode-api',
    'elhub-api',
    'hark-partner-api',
    'mittnte-adminapi',
    'mittnte-api',
    'nordpool-dayahead-import',
    'send-api',
    'strombestilling-api',
    'uc-meteringvalues',
    'uc-eventconsumer'
];

export const markedApplications = markedApplicationsNames
    .map(name => new App(
        name,
        `${name}.marked.azure.nte.${config.isDev() ? 'dev' : 'no'}`,
        LAWUrl(config.subscriptionId, resourceGroup(name))
    ));

function LAWUrl(subscriptionId: string, resourceGroup: string): string {
    return `https://portal.azure.com/#@nteholding.onmicrosoft.com/resource/subscriptions/${subscriptionId}/resourceGroups/${resourceGroup}/providers/Microsoft.Web/sites/${resourceGroup}-app/analytics`
}
