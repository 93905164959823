import { useParams } from "react-router-dom";
import { markedApplications } from "../applications.ts";
import {
    applicationErrorLogsQuery,
    applicationLogsQuery, applicationWarnLogsQuery,
    Logline,
    LogsQuery,
    traceIdQuery,
    useQueryApplication
} from "../useQueryLAW.ts";
import { Durations, QueryTimeInterval } from "@azure/monitor-query";
import { useState } from "react";

export function ApplicationLogs() {
    const { appId } = useParams() as {appId: string};
    const [queryTimeInterval, setQueryTimeInterval] = useState<QueryTimeInterval>({ duration: Durations.fiveMinutes });

    const app = markedApplications.find(a => a.name == appId);
    if(!app) {
        return (<div>Not found</div>)
    } else {
        const {name} = app;
        const logsQuery = applicationLogsQuery(name, queryTimeInterval);
        return (<div>
            <h2>Logger for {name}</h2>
            <QueryIntervalSelector current={queryTimeInterval} setter={(newValue: QueryTimeInterval) => setQueryTimeInterval(newValue)} />
            <Logs query={logsQuery} />
        </div>)
    }

}

export function TraceIdLogs() {
    const { query } = useParams() as {query: string};
    const queryComponents = parsePathQuery(query);
    if(queryComponents) {
        const logsQuery = traceIdQuery(
            queryComponents.traceId, {
                startTime: queryComponents.start,
                endTime: queryComponents.end,
            })
        return (<div>
            <Logs query={logsQuery} />
        </div>)
    } else {
        return (<div>
            Could not parse {query} as traceId, start, end.
        </div>)
    }
}
export function IntervalLogs() {
    const { appId, query } = useParams() as {appId: string, query: string};
    const queryComponents = parsePathIntervalQuery(query);
    if(queryComponents) {
        const logsQuery = applicationLogsQuery(
            appId, {
                startTime: queryComponents.start,
                endTime: queryComponents.end,
            })
        return (<div>
            <Logs query={logsQuery} />
        </div>)
    } else {
        return (<div>
            Could not parse {query} as start, end.
        </div>)
    }
}

export function ErrorIntervalLogs() {
    const { appId, query } = useParams() as {appId: string, query: string};
    const queryComponents = parsePathIntervalQuery(query);
    if(queryComponents) {
        const logsQuery = applicationErrorLogsQuery(
            appId, {
                startTime: queryComponents.start,
                endTime: queryComponents.end,
            })
        return (<div>
            <Logs query={logsQuery} />
        </div>)
    } else {
        return (<div>
            Could not parse {query} as start, end.
        </div>)
    }
}

export function WarnIntervalLogs() {
    const { appId, query } = useParams() as {appId: string, query: string};
    const queryComponents = parsePathIntervalQuery(query);
    if(queryComponents) {
        const logsQuery = applicationWarnLogsQuery(
            appId, {
                startTime: queryComponents.start,
                endTime: queryComponents.end,
            })
        return (<div>
            <Logs query={logsQuery} />
        </div>)
    } else {
        return (<div>
            Could not parse {query} as start, end.
        </div>)
    }
}

function Logs(props: {query: LogsQuery}) {
    const { data, loading, error, reload } = useQueryApplication(
        props.query
    )
    if (error) return <p>Error: {error.message}</p>;

    return (<>
        <div>
        <input type="button" value="Reload" onClick={() => reload()}/>{loading && <span>Loading...</span>}
        </div>
        <div>
            {data?.map(ll => <LogLineComponent key={ll.id} logLine={ll} />)}
        </div>
    </>)
}

function LogLineComponent(props: {logLine: Logline}) {
    const line = props.logLine.line;
    if(line.startsWith('{') || line.startsWith(' {')) {
        return <FormattedLogLine logLine={props.logLine} />
    } else {
        return <RawLogLine logLine={props.logLine} />
    }
}


interface ParsedLogLine {
    timestamp: string
    mdc: {traceId?: string}
    level: string
    loggerName: string
    message: string
    exception?: ParsedExceptionLine
}
interface ParsedExceptionLine {
    exceptionType: string
    message:string
    frames: ExceptionFrame[]
}

interface ExceptionFrame {
    class: string
    method: string
    line?: number
}
const cleanJson = (dirtyJson: string): ParsedLogLine => {
    // This preserves:
    // \t (tab, \u0009)
    // \n (newline, \u000A)
    // \r (carriage return, \u000D)
    // eslint-disable-next-line no-control-regex
    const cleaned = dirtyJson.replace(/[\u0000-\u0008\u000B\u000C\u000E-\u001F]/g, '');
    return JSON.parse(cleaned) as ParsedLogLine;

}
function FormattedLogLine(props: {logLine: Logline}) {
    const {app, line} = props.logLine;
    try {
        const parsed = cleanJson(line);
        return (<div className="log-line">
            <span className="log-line-element app">{app}</span>&nbsp;
            <span className="log-line-element timestamp">{parsed.timestamp}</span>&nbsp;
            <span className="log-line-element traceid">{parsed.mdc.traceId}</span>&nbsp;
            <span className="log-line-element level">{parsed.level}</span>&nbsp;
            <span className="log-line-element loggername">{parsed.loggerName}</span>&nbsp;
            <span className="log-line-element message">{parsed.message}</span>&nbsp;
            {parsed.exception && <LogLineException exception={parsed.exception}/>}
        </div>);
    } catch (e) {
        console.log(props.logLine);
        console.log(e);
        return <RawLogLine logLine={props.logLine} />
    }
}

function RawLogLine(props: {logLine: Logline}) {
    const {app, line} = props.logLine;
    return (<div className="log-line">
        <span className="log-line-element app">{app}</span>&nbsp;
        <span className="log-line-element raw">{line}</span>
    </div>)
}

function LogLineException(props: {exception: ParsedExceptionLine}) {
    const {exceptionType, message, frames} = props.exception;
    // TODO toggle .startsWith('no.nte')
    return (<div className="log-line-element exception">
        <div className="exception-message">{exceptionType} {message}</div>
        {frames.filter(f => !!f.line )
            .map((f, i) => <div className="exception-frame" key={i}>{f.class}:{f.line} {f.method}</div>)}
    </div>)
}

const durations = [Durations.fiveMinutes, Durations.thirtyMinutes, Durations.oneHour, Durations.fourHours, Durations.oneDay, Durations.twoDays, Durations.threeDays, Durations.sevenDays]
function QueryIntervalSelector(props: {current: QueryTimeInterval, setter: (value: QueryTimeInterval) => void}) {
    // @ts-expect-error Compiler thinks current only contains startTime, endTime
    const {current: {duration}, setter} = props;
/*    const [fromTime, setFromTime] = useState<Date | null>(null);
    const [toTime, setToTime] = useState<Date | null>(null);*/

    return (<div>
        <div className="duration-selector">
            <label htmlFor="duration">Duration </label>
            <select name="duration"
                    onChange={event => setter({duration: event.target.value})}
                    value={duration}>
                {durations.map(d => <option key={d} value={d} >{d}</option>)}
            </select>
        </div>
        <div className="interval-selector">
            Tidsintervall her
        </div>
    </div>)
}

function parseDate(dateString: string): Date | null {
    return new Date(dateString);
}

function parsePathQuery(query: string): { traceId: string, start: Date, end: Date } | null {
    const [traceId, startString, endString] = query.split(",");
    const start = parseDate(startString);
    const end = parseDate(endString);
    if(traceId && start && end) {
        return {
            traceId,
            start: start,
            end: end
        };
    } else {
        return null;
    }

}

function parsePathIntervalQuery(query: string): { start: Date, end: Date } | null {
    const [startString, endString] = query.split(",");
    const start = parseDate(startString);
    const end = parseDate(endString);
    if(start && end) {
        return {
            start: start,
            end: end
        };
    } else {
        return null;
    }

}